import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Block } from "glamor/jsxstyle";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

/**
 * About page
 *
 * Should give an overview of my background, let the user know what I'm
 * currently working on, outline my quarterly goals with my progress,
 * and link to a user manual for working with me, along with how to reach me
 */
const About = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  // days old calculation
  let today = new Date();
  let birthday = new Date("7/16/1996 0:00");
  today.setHours(0, 0, 0, 0);
  birthday.setHours(0, 0, 0, 0);
  let diff = (+today - +birthday) / (24 * 60 * 60 * 1000);
  let converter = require("number-to-words");

  return (
    <Layout title={siteTitle}>
      <h1 className="aboutHeader">Hey, I'm Conor 👋🏼</h1>
      <SEO
        title={"About | Conor Dewey"}
        description={`Hey, I'm Conor. I operate at the intersection of product, growth, and data science. This means breaking down complex problems, building useful things,
          and experimenting with new ideas.`}
        pathname={"about"}
      />
      <section
        style={{
          fontSize: "1.1rem"
        }}
      >
        <Block paddingBottom={"1.4rem"}>
          Born {converter.toWords(diff)} days ago. I work in startups and have
          lots of different interests. Right now, I'm focused on Product at{" "}
          <a
            href="https://www.metabase.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Metabase
          </a>
          .
        </Block>
        <Block paddingBottom={"1.4rem"}>
          I operate at the intersection of product, growth, and data science.
          This means breaking down complex problems, building useful things, and
          experimenting with new ideas. If I'm not working on a project, you
          might find me <Link to={"/reading"}>reading</Link> or{" "}
          <Link to={"/blog"}>writing</Link> something new.
        </Block>

        <Block paddingBottom={"2.5rem"}>
          If you want to get in touch, I'm most responsive over{" "}
          <a href="mailto:conor.dewey3@gmail.com">email</a> and I tend to be
          pretty active on{" "}
          <a
            href="https://twitter.com/cdeweyx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>{" "}
          as well.
        </Block>
      </section>
      <Block>
        {" "}
        <div className="aboutSubheader">Previously</div>
      </Block>
      <section
        style={{
          fontSize: "1.1rem",
          lineHeight: "32px"
        }}
      >
        <Block paddingBottom={"2.5rem"}>
          Most recently, I worked on product growth at{" "}
          <a
            href="https://www.hugo.team/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hugo
          </a>
          , an early-stage startup later acquired by           <a
            href="https://calendly.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Calendly
          </a>. Before that, I was
          born and raised in Virginia and graduated from{" "}
          <a href="https://vt.edu/" target="_blank" rel="noopener noreferrer">
            Virginia Tech
          </a>. I worked on data-related things at{" "}
          <a
            href="https://www.mastercard.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mastercard
          </a>{" "}
          and{" "}
          <a
            href="https://unity.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unity
          </a>{" "}
          before joining the Growth team at{" "}
          <a
            href="https://www.squarespace.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Squarespace
          </a>{" "}
          as a Data Scientist. I've also published an interview course with{" "}
          <a
            href="https://www.datacamp.com/courses/preparing-for-statistics-interview-questions-in-python"
            target="_blank"
            rel="noopener noreferrer"
          >
            DataCamp
          </a>{" "}
          and sometimes work part-time with startups on different
          projects.
        </Block>

        <Block className="aboutSubheader">User manual</Block>
        <Block>
          I created a playbook on how to{" "}
          <Link to={"/manual"}>work with me</Link>. It captures some of my
          strengths, weaknesses, and principles that I aim to follow.
        </Block>
        <Block
          fontSize="1.1rem"
          fontWeight="500"
          marginTop="0.8rem"
          boxShadow="none"
        >
          <section>
            <Link to={"/manual"}>More about me →</Link>
          </section>
        </Block>
      </section>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

/**
 * 
 *         <Block className="aboutSubheader">Quantified Self</Block>
        <Block>
          I track all sorts of <Link to={"/open"}>personal metrics</Link> to
          learn more about myself and my well-being. Enjoy this
          too-much-information view into my current state.
        </Block>
        <Block
          fontSize="1.1rem"
          fontWeight="500"
          marginTop="0.8rem"
          boxShadow="none"
        >
          <section>
            <Link to={"/open"}> See the data →</Link>
          </section>
        </Block>
 */
